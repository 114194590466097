export default class ListOrganize4 {
  constructor(list) {
    this.list = list;
    this.items = list.querySelectorAll("li");
    this.length = this.items.length;
    this.flag = false;
  }
  organizeDataList() {
    this.flag = this.length % 4 === 0 ? false : true;
    while (this.flag) {
      let skelton = document.createElement("li");
      skelton.classList.add(this.items[0].className);
      this.list.appendChild(skelton);
      this.items = this.list.querySelectorAll("li");
      this.length = this.items.length;
      if (this.length % 4 === 0) break;
    }
  }
}
