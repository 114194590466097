import axios from 'axios'

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const errorMessageParser = (e, defaultMessage) => {
  if (e && e.response && e.response.data && e.response.data.messages) {
    return e.response.data.messages
  } else {
    return defaultMessage || e.message
  }
}

export async function createFavorites(params) {
  try {
    let result = await axios.post('/favorites.json', params)
    return result.data
  } catch (e) {
    throw new Error(errorMessageParser(e))
  }
}

export async function deleteFavorites(params) {
  try {
    let result = await axios.delete('/favorites.json', { data: params })
    return result.data
  } catch (e) {
    throw new Error(errorMessageParser(e))
  }
}

export async function createComments(params) {
  try {
    let result = await axios.post('/comments.json', params)
    return result.data
  } catch (e) {
    if (e.response.status == 403) {
      throw e.response
    }
    throw new Error(errorMessageParser(e))
  }
}

export async function deleteComments(id) {
  try {
    let result = await axios.delete(`/comments/${id}.json`)
    return result.data
  } catch (e) {
    throw new Error(errorMessageParser(e))
  }
}

export async function fetchProductData(id) {
  try {
    let result = await axios.get(`/products/${id}.json`)
    return result.data
  } catch(e) {
    throw new Error(errorMessageParser(e))
  }
}

export async function productFavorite(params) {
  try {
    let result = await axios.post('/favorite_products', params)
    return result.data
  } catch(e) {
    throw new Error(errorMessageParser(e))
  }
}

export async function productFavoriteDelete(id) {
  try {
    let result = await axios.delete(`/favorite_products/${id}`)
    return result.data
  } catch(e) {
    throw new Error(errorMessageParser(e))
  }
}

export async function fetchPostData(id) {
  try {
    let result = await axios.get(`/posts/${id}.json`)
    return result.data
  } catch(e) {
    throw new Error(errorMessageParser(e))
  }
}
