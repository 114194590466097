<template>
  <div v-if="post" class="relatePost">
    <a :href="'/' + post.id" :title="post.title" target="_self">
      <figure class="relatePostImage">
        <img class="img" :src="post.img" :alt="post.title" />
      </figure>
      <div class="relatePostDesc">
        <div v-if="showAuthor" class="relatePostDate">{{ post.date }} / {{ post.author }}</div>
        <div class="relatePostTitle">{{ post.title }}</div>
      </div>
    </a>
  </div>
</template>

<script>
import { fetchPostData } from "../api";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    showAuthor: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      post: null
    };
  },
  mounted: async function() {
    this.post = await fetchPostData(this.id);
  }
};
</script>

<style scoped lang="scss">
.relatePost {
  padding-bottom: 20px;

  > a {
    display: flex;
    text-decoration: none !important;
  }

  &Image {
    width: 15%;

    img {
      margin: 0 !important;
    }
  }

  &Desc {
    font-size: 1.6rem;
    flex: 1;
  }

  &Category {
    text-align: right;
  }

  &Title {
    padding-left: 15px;
    color: black;
    text-align: justify;
  }

  .relatePostDesc {
    display: flex;

    .relatePostTitle {
      margin: auto 0;
    }
  }
}

@media (max-width: 1000px) {
  .relatePostDesc {
    font-size: 1.4rem;
  }
}

@media (max-width: 630px) {
  .relatePost {
    border-bottom: 1px solid #999;
    &Image {
      width: 30%;
    }
  }

  .relatePost:last-child {
    border-bottom: none !important;
  }
}
</style>
