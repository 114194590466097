<template>
  <article class="rankingSection">
    <div class="rankingSectionTitle">
      <span>{{ title }}</span>
      <i class="fa" :class="'fa-' + icon"></i>
    </div>
    <ul class="sideArticleList rankingList" v-if="posts">
      <!-- <transition name="slide" mnode="out-in"> -->
        <li class="sideArticleItem" :key="post.id">
          <div class="c-articleCard articleMd rankingCard">
            <a class="imageBlock" :href="'/' + post.id" :title="post.title" target="_self">
              <figure class="frame">
                <img class="img" :src="post.img" :alt="post.title" />
              </figure>
            </a>
            <a class="descriptBlock" :href="'/' + post.id" :title="post.title" target="_self">
              <div class="head">{{ post.title }}</div>
            </a>
          </div>
        </li>
      <!-- </transition> -->
    </ul>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    posts: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: false
    }
  },
  data: function() {
    return {
      post: this.posts[0],
      post_index: 0,
      rotation: null
    };
  },
  created: function() {
    this.setAutoRotate();
  },
  beforeDestroy() {
    clearInterval(this.rotation);
  },
  methods: {
    setAutoRotate: function() {
      this.rotation = setInterval(() => {
        this.next();
      }, 5000);
    },
    next: function() {
      if (this.post_index === this.posts.length - 1) {
        this.post_index = 0;
      } else {
        this.post_index++;
      }
      this.post = this.posts[this.post_index];
    }
  }
};
</script>

<style scoped>
.sideArticleList {
  height: auto;
  transform: scaleY(1);
  transform-origin: top;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(0%, 0);
}
.slide-leave-to {
  transform: translate(0%, 0);
  display: none;
  opacity: 0;
}
</style>