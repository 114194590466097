<template>
  <div v-if="templateType === 'under'" class="favorite-box-under">
    <button class="likeBtn-under" v-bind:class="{isLike: postIsFav}" @click="like()">
      <span class="likeIcon"><i v-bind:class="{far: !postIsFav, fas: postIsFav}" class="fa-heart"></i></span>
      <span v-if="postIsFav" class="likeComment">已加入我的最愛</span>
      <span v-else class="likeComment">加入我的最愛</span>
    </button>
  </div>
  <button v-else class="likeBtn" v-bind:class="{isLike: postIsFav}" @click="like()">
    <span class="likeIcon">{{isFavorite()}}</span>
    <span class="likeAmount">{{ postLikes }}</span>
  </button>
</template>

<script>
import { createFavorites, deleteFavorites } from "../api";
import _ from "lodash";

export default {
  props: ["loggedIn", "postId", "likes", "fav", "templateType"],
  data: function() {
    return {
      postLikes: 0,
      postIsFav: false
    };
  },
  methods: {
    like: async function() {
      try {
        if (!this.loggedIn) {
          window.location.href = "/users/sign_in";
          return;
        }
        if (this.postIsFav) {
          await deleteFavorites({ post_id: this.postId });
          this.$root.$emit('DISLIKE', this.postId)
        } else {
          await createFavorites({ favorite: { post_id: this.postId } });
          this.$root.$emit('LIKE', this.postId)
        }
      } catch (e) {
        console.log(e);
      }
    },
    isFavorite: function() {
      if (this.postIsFav) {
        return `♥`;
      } else {
        return `♡`;
      }
    }
  },
  created: function() {
    this.postLikes = parseInt(this.likes, 10);
    this.postIsFav = this.fav;
    const self = this
    this.$root.$on('LIKE', function (postId) {
      if (self.postId === postId) {
        self.postLikes += 1;
        self.postIsFav = true;
      }
    })
    this.$root.$on('DISLIKE', function (postId) {
      if (self.postId === postId) {
        self.postLikes -= 1;
        self.postIsFav = false;
      }
    })
  }
};
</script>

<style scoped>
</style>
