<template>
  <div class="productContainer">
    <div class="imgBox">
        <img v-if="product && product.image_url" :src="product.image_url" :alt="product.name" >
        <button
          v-if="canRender"
          class="likeBtn top-right"
          :class="{ isLike: favorited }"
          @click="likeOrDislikeAction()"
        >
          <span class="likeIcon">{{ isFavorited }}</span>
          <span class="likeAmount">{{ favoritesCount }}</span>
        </button>
    </div>
    <div v-if="product && product.html" v-html="product.html" class="linkBox"></div>
  </div>
</template>

<script>
import {
  fetchProductData,
  productFavorite,
  productFavoriteDelete
} from "../api";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      canRender: false,
      favorited: false,
      loggedIn: false,
      favoriteProductId: null,
      favoritesCount: 0,
      product: null
    };
  },
  mounted: function() {
    this.fetchFavorite();
  },
  methods: {
    fetchFavorite: async function() {
      let result = await fetchProductData(this.id);

      if (!result.non_exist) {
        this.canRender = true;
        this.product = result.product;
        this.favorited = result.favorited;
        this.loggedIn = result.loggedIn;
        this.favoritesCount = result.product.favorites_count;
        this.favoriteProductId = result.favorite_product_id;
      }
    },
    likeOrDislikeAction: async function() {
      if (this.loggedIn) {
        if (this.favorited) {
          this.dislike();
        } else {
          this.like();
        }
      } else {
        location.href = "/users/sign_in";
      }
    },
    like: async function() {
      let result = await productFavorite({
        favorite_product: {
          product_id: this.id
        }
      });
      if (result) {
        this.favoritesCount += 1;
        this.favorited = true;
        this.favoriteProductId = result;
      }
    },
    dislike: async function() {
      if (this.favoriteProductId == null) {
        return;
      }

      let result = await productFavoriteDelete(this.favoriteProductId);

      if (result == "ok") {
        this.favoritesCount -= 1;
        this.favorited = false;
        this.favoriteProductId = null;
      }
    }
  },
  computed: {
    isFavorited: function() {
      if (this.favorited) {
        return `♥`;
      } else {
        return `♡`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.productContainer {
    max-width: 360px;
    margin: 40px auto;
    padding-top: 24px;
    text-align: center;
    .imgBox{
        display: inline-block;
        position: relative;
        width: auto;
        margin: 0 auto 20px auto;
        img{
            diplay: block;
            z-index: 1;
        }
        .top-right {
            position: absolute;
            top: -12px;
            right: -24px;
            z-index: 2;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border: 1px solid #e1e1e1;
            transition: all 0.2s ease;
            .likeIcon{
                margin-right: 0;
            }
            .likeAmount{
                display: none;
            }
            &:hover{
                background: #fff5f5;
                border-color: #ffcccc;
                .likeIcon{
                    color: #FF6666;
                }
            }
            &.isLike{
                border-color: #ffcccc;
                .likeIcon{
                    color: #FF6666;
                }
            }
        }
    }
}
</style>
