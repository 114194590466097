import Swiper from 'swiper';
import ListOrganize4 from './ListOrganize4';
import $ from 'jquery'

window.onload = function () {
  var mySwiper = new Swiper('.heroSlider', {
    // Optional parameters
    // slidesPerView: 'auto',
    slidesPerView: 'auto',
    centeredSlides: true,
    direction: 'horizontal',

    loop: true,
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
    },
    autoplay: {
      delay: 4000,
    },
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // And if we need scrollbar
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    // },
    spaceBetween: 10,
    breakpoints: {
      // 1000: {
      //   slidesPerView: '1.5',
      // },
      630: {
        slidesPerView: '1',
        spaceBetween: 0
      }
    }
  });
}

window.addEventListener("DOMContentLoaded", () => {
  let elMain = document.querySelector(".js-main");

  let mypageMenu = document.querySelector(".js-mypageMenu");
  let hamburger = document.querySelector(".js-hamburger");
  let overLay = document.querySelector(".js-overLay");
  if (document.querySelector(".js-notice")) {
    let notice = document.querySelector(".js-notice");
    if (notice.textContent) {
      notice.classList.add("active");
      setTimeout(() => {
        notice.classList.remove("active");
      }, 5000);
    }
  }

  hamburger.addEventListener("click", () => {
    mypageMenu.classList.contains("active") ? mypageMenu.classList.remove("active") : mypageMenu.classList.add("active");
    overLay.classList.contains("active") ? overLay.classList.remove("active") : overLay.classList.add("active");
    elMain.classList.contains("active") ? elMain.classList.remove("active") : elMain.classList.add("active");
  });
  overLay.addEventListener("click", () => {
    mypageMenu.classList.contains("active") ? mypageMenu.classList.remove("active") : mypageMenu.classList.add("active");
    overLay.classList.contains("active") ? overLay.classList.remove("active") : overLay.classList.add("active");
    elMain.classList.contains("active") ? elMain.classList.remove("active") : elMain.classList.add("active");
  });

  if (document.querySelector(".js-userSnsList")) {
    let userSnsList = document.querySelector(".js-userSnsList");
    const listOrganize = new ListOrganize4(userSnsList);
    listOrganize.organizeDataList();
  }

  let elScrollable = document.documentElement;
  let bannerModal = document.querySelector(".js-bannerModal");
  let close = document.querySelector(".js-close");
  let closeAlp = document.querySelector(".alp-close");
  let show = true;
  if (bannerModal) {
    window.addEventListener("scroll", () => {
      let scrollTop = elScrollable.scrollTop;
      let windowHeight = elScrollable.clientHeight;
      let pageHeight = elScrollable.scrollHeight;
      let marginBottom = 0;
      if (scrollTop + windowHeight + marginBottom >= pageHeight && show) {
        bannerModal.classList.add("active");
        close.addEventListener("click", () => {
          bannerModal.classList.remove("active");
          show = false;
        });
        closeAlp.addEventListener("click", () => {
          bannerModal.classList.remove("active");
          show = false;
        });
      }

      // for SP
      if (document.body.scrollTop + window.innerHeight >= document.body.scrollHeight && show) {
        bannerModal.classList.add("active");
        close.addEventListener("click", () => {
          bannerModal.classList.remove("active");
          show = false;
        });
        closeAlp.addEventListener("click", () => {
          bannerModal.classList.remove("active");
          show = false;
        });
      }
    });
  }
  if (document.querySelector(".myButton")) {
    let myButtons = Object.keys(document.querySelectorAll(".myButton")).map(key => document.querySelectorAll(".myButton")[key]);
    myButtons.forEach(item => {
      item.addEventListener("touchstart", () => {
      });
    });
  }

});

//user agent
var uaDevice = (function () {
  var ua = navigator.userAgent;
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
    return 'sp';
  }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
    return 'tab';
  }else{
    return 'other';
  }
})();
var closedFlug = false;

$(function(){
  $(window).on('load resize scroll', function () {
    //control-btns - offset
    var controlBtnPos = $('.control-btns').offset().top + $('.control-btns').outerHeight();
    var footerPos = $('.l-footer').offset().top;
    if(controlBtnPos >= footerPos - 20){
      $('.control-btns').addClass('fixpoint');
    } else{
      $('.control-btns').removeClass('fixpoint');
    }
    //post page raise banner
    if($('.js-bannerRaise').length && uaDevice == 'sp'){
      var triggerObj = $('.authors-list-btn').offset().top;
      var raisePoint = triggerObj - $(window).height();
      if($(window).scrollTop() > raisePoint){
        $('.js-bannerRaise').addClass('active');
        $('.control-btns').addClass('raised');
        $('.l-footer .inside').addClass('raised');
      }
    }
    //top page sns popup
    if($('.js-topBannerModal').length){
      var triggerObjct = $('.authorsBlock').offset().top;
      var popPoint = triggerObjct - $(window).height();
      if($(window).scrollTop() > popPoint + 200){
        if(closedFlug == false){
          $('.js-topBannerModal').addClass('active');
        }
      }
      $('.js-topClose, .alp-topClose').on('click', function () {
        $('.js-topBannerModal').removeClass('active');
        closedFlug = true;
      });
    }
    quoteImgText();
  });
  $(window).on('load resize', function () {
    //calender date select navigation (smartphone)
    if($('.dateSelect').length && $(window).width() < 631){
      var todayDate = new Date();
      var todayYMD = todayDate.getFullYear() + ("0"+(todayDate.getMonth() + 1)).slice(-2) + ("0"+todayDate.getDate()).slice(-2);
      var currentDateItem = $('.dateSelect a[href="#cal-'+ todayYMD +'"]');
      if(currentDateItem.length){
        var currentDateItemPos = currentDateItem.offset().left;
        $('.dateSelect').scrollLeft(currentDateItemPos - $(window).width() / 2 + 20);
        return false;
      } else{
        return false;
      }
    }
  });
  //top scroll btn - click
  $('.top-scroll').on('click',function() {
    $('body,html').stop().animate({scrollTop: 0},200,'swing');
    return false;
  });
  //share btn - click
  if(uaDevice == 'other'){
    $('.share-btn').hover(function() {
      $(this).find('.sns-btn').toggleClass('active');
    });
  } else{
    $('.share-btn').on('click',function() {
      $(this).find('.sns-btn').toggleClass('active');
    });
  }
  //header search btn
  $('.header-search').on('click',function () {
    $('.search-overlay').addClass('active');
  });
  //search overlay close btn
  $('.search-overlay .search-close').on('click',function () {
    $('.search-overlay').removeClass('active');
  });
  //post page index toggle
  $('.postpage-index h3').on('click',function () {
    $(this).toggleClass('open');
    $('.postpage-index > ul').toggleClass('open');
  });
  //right panel more toggle
  $('.more-toggle-btn').on('click',function () {
    $(this).addClass('off');
    $(this).next('.more-toggle-content').addClass('active');
  });
  //post page index scroll
  $('.indexListItem').on('click',function () {
    var xpath = $(this).attr('data-target-object');
    var element = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
    var headerHeight = $('.l-header').outerHeight();
    var targetPos = element.offsetTop;
    $('body,html').stop().animate({scrollTop: targetPos - headerHeight - 20});
  });
  //page scroll
  $('.pageScrollNav a[href^="#"]').off();
  $('.pageScrollNav a[href^="#"]').on('click',function () {
    var href = $(this).attr("href");
    var headerMargin = $(this).attr("data-hmargin");
    if(headerMargin != null){
      var hMargin = headerMargin;
    }else{
      var hMargin = 20;
    }
    var hash = href == "#" || href == "" ? 'html' : href;
    var target = $(hash);
    var headerHeight = $('.l-header').outerHeight();
    var position = target.offset().top;
    $('body,html').stop().animate({scrollTop: position - headerHeight - hMargin}, 'linear');
    return false;
  });
  //Google calender add schedule btn
  $('.addGcalBtn a').off();
  $('.addGcalBtn a').on('click', function() {
    var gTitle = encodeURIComponent($(this).attr("data-title"));
    var gText = encodeURIComponent($(this).attr("data-title") + ' 發售日');
    var gStart = $(this).attr("data-start");
    var gEnd = $(this).attr("data-end");
    var gURL = encodeURIComponent(window.location);
    var gSiteName = encodeURIComponent('Beauty Upgrade - 陪你一起美力升級');
    var gCalUrl = 'http://www.google.com/calendar/event?action=TEMPLATE' +
        '&text=' + gTitle +
        '&details=' + gText +
        '&dates=' + gStart + '/' + gEnd +
        '&trp=' + 'false' +
        '&sprop=' + gURL +
        '&sprop='   + 'name:' + gSiteName;
    window.open(gCalUrl);
    return false;
  });
  //image quote styling class
  function quoteImgText(){
    $('a:contains("圖片來源")').closest('p').addClass('quoteImgAnc');
  }

});
