<template>
  <span class="p-commentContents">
    <div class="commentPost">
      <div class="commentHead">留言:</div>
      <div class='commentBody' v-if="userId">
        <textarea class="commentArea" v-model="comment" placeholder="請輸入您的留言"></textarea>
        <button @click="send()" class="c-btnDef primary sizeMn">回覆</button>
      </div>
      <div class='commentBody' v-else>
        <a href='/users/sign_in'>
          <textarea disabled class="commentArea" v-model="comment" placeholder="請登入後留言"></textarea>
          <button class="c-btnDef primary sizeSm">登入後回覆</button>
        </a>
      </div>
    </div>
    <ul class="commentsList">
      <li class="commentsItem" v-for="c in comments" v-bind:key="c.id">
        <div class='c-userComment'>
          <div class='userCommentHead'>
            <div class='userName'>{{ c.user.name || '小BU友' }}  </div>
            <div class="commentDate">{{ c.created_at | formatDate }}</div>
            <button class="c-btnDef mdGray sizeMn" v-if="canDelete(c.user.id)" @click="destroy(c.id)" >刪除</button>
          </div>
          <div class='userCommentBody' v-html="replaceURLWithHTMLLinks(c.comment)">
          </div>
        </div>
      </li>
    </ul>
  </span>
</template>

<script>
import _ from "lodash";

import { createComments, deleteComments } from "../api";

export default {
  props: {
    initComments: Array,
    postId: Number,
    userId: Number,
  },
  data: function() {
    return {
      comment: '',
      comments: [],
      processing: false
    };
  },
  methods: {
    send: async function(id) {
      if (this.processing === true) {

        return
      }
      this.processing = true
      try {
        const result = await createComments({ comment: { post_id: this.postId, comment: this.comment } })
        this.comments.unshift(result)
        this.comment = ''
      } catch (e) {
        if (e.status == 403) {
          this.$toasted.error("You can't not authorize.")
        } else {
          console.log(e);
        }
      }
      this.processing = false
    },
    canDelete: function(userId) {
      return userId === this.userId
    },
    destroy: async function(id) {
      if (this.processing === true) {
        return
      }
      this.processing = true
      try {
        const i = _.findIndex(this.comments, function(c) { return c.id === id })
        if (i < 0) {
          return
        }
        await deleteComments(id)
        this.comments.splice(i, 1);
      } catch (e) {
        console.log(e);
      }
      this.processing = false
    },
    replaceURLWithHTMLLinks: function(text, defaultTruncate = 50){
      let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return text.replace(exp, function ($1) {
        return '<a target="_blank" rel="nofollow" href="' + $1 + '">' + $1.slice(0, defaultTruncate) + '</a>';
      });
    }
  },
  created: function() {
    this.comments = this.initComments
  }
};
</script>

<style scoped>
</style>
