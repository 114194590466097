/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

import "@babel/polyfill";
import "lazysizes"
import "lazysizes/plugins/unveilhooks/ls.unveilhooks"
import '../src/css/index';
import '../src/js/ListOrganize3';
import '../src/js/home';

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

import Vue from 'vue/dist/vue.esm'
import moment from 'moment'
import Favorites from '../src/js/vue/favorites.vue'
import Comments from '../src/js/vue/comments.vue'
import Product from '../src/js/vue/product.vue'
import Ranking from '../src/js/vue/ranking.vue'
import RelatePost from '../src/js/vue/RelatePost.vue'
import Toasted from 'vue-toasted';

Vue.prototype.$toasted = Toasted;
Vue.component('favorites', Favorites)
Vue.component('comments', Comments)
Vue.component('product', Product)
Vue.component('ranking', Ranking)
Vue.component('relate-post', RelatePost)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm')
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]',
  })
  lazySizes.init()

  let noAd = document.getElementById('no-ad')
  if (!noAd) {
    // paula script tag
    // let adScriptTag = document.createElement('script');
    // adScriptTag.type = "text/javascript";
    // adScriptTag.setAttribute('async', '');
    // adScriptTag.setAttribute('data-cfasync', false);
    // adScriptTag.text = "var truvidScript = document.createElement('script'); truvidScript.async = true; truvidScript.setAttribute('data-cfasync','false'); truvidScript.type = 'text/javascript'; truvidScript.src = '//stg.truvidplayer.com/index.php?sub_user_id=597&widget_id=2972&playlist_id=2142&m=a&cb=' + (Math.random() * 10000000000000000); var currentScript = document.currentScript || document.scripts[document.scripts.length - 1]; currentScript.parentNode.insertBefore(truvidScript, currentScript.nextSibling);";
    // let element = document.getElementById('adPart')
    // if (element) {
    //   element.appendChild(adScriptTag);
    // }

    // adsense script tag 1
    let adsenseScriptTag1 = document.createElement('ins')
    adsenseScriptTag1.setAttribute('class', 'adsbygoogle')
    adsenseScriptTag1.setAttribute('style', 'display:block; width: 350px; margin: 0 auto;')
    adsenseScriptTag1.setAttribute('data-full-width-responsive', 'true')
    adsenseScriptTag1.setAttribute('data-ad-format', 'fluid')
    adsenseScriptTag1.setAttribute('data-ad-layout-key', '-7c+de+1z+n+3')
    adsenseScriptTag1.setAttribute('data-ad-client', 'ca-pub-2708610541573516')
    adsenseScriptTag1.setAttribute('data-ad-slot', '1904914005')
    let adsenseScriptTag2 = document.createElement('script')
    adsenseScriptTag2.text = "(adsbygoogle = window.adsbygoogle || []).push({});"
    let adSenseContainers = document.getElementsByClassName('adSense1')
    for ( let i = 0; i < adSenseContainers.length; i++ ) {
      const element = adSenseContainers[i]
      element.appendChild(adsenseScriptTag1)
      element.appendChild(adsenseScriptTag2)
    }

    // adsense script tag 2
    adsenseScriptTag1 = document.createElement('ins')
    adsenseScriptTag1.setAttribute('class', 'adsbygoogle')
    adsenseScriptTag1.setAttribute('style', 'display:block; width: 350px ;margin: 0 auto;')
    adsenseScriptTag1.setAttribute('data-full-width-responsive', 'true')
    adsenseScriptTag1.setAttribute('data-ad-format', 'fluid')
    adsenseScriptTag1.setAttribute('data-ad-layout-key', '-7c+de+1z+n+3')
    adsenseScriptTag1.setAttribute('data-ad-client', 'ca-pub-2708610541573516')
    adsenseScriptTag1.setAttribute('data-ad-slot', '7753913158')
    adsenseScriptTag2 = document.createElement('script')
    adsenseScriptTag2.text = "(adsbygoogle = window.adsbygoogle || []).push({});"
    adSenseContainers = document.getElementsByClassName('adSense2')
    for ( let i = 0; i < adSenseContainers.length; i++ ) {
      const element = adSenseContainers[i]
      element.appendChild(adsenseScriptTag1)
      element.appendChild(adsenseScriptTag2)
    }

    // adsense script tag 3
    adsenseScriptTag1 = document.createElement('ins')
    adsenseScriptTag1.setAttribute('class', 'adsbygoogle adRes')
    adsenseScriptTag1.setAttribute('style', 'display:block')
    adsenseScriptTag1.setAttribute('data-full-width-responsive', 'true')
    adsenseScriptTag1.setAttribute('data-ad-format', 'fluid')
    adsenseScriptTag1.setAttribute('data-ad-layout-key', '-7c+de+1z+n+3')
    adsenseScriptTag1.setAttribute('data-ad-client', 'ca-pub-2708610541573516')
    adsenseScriptTag1.setAttribute('data-ad-slot', '4431839145')
    adsenseScriptTag2 = document.createElement('script')
    adsenseScriptTag2.text = "(adsbygoogle = window.adsbygoogle || []).push({});"
    adSenseContainers = document.getElementById('ads_container')
    if (adSenseContainers) {
      adSenseContainers.appendChild(adsenseScriptTag1)
      adSenseContainers.appendChild(adsenseScriptTag2)
    }

    // popin ad
    let pa = document.createElement('script')
    pa.type = 'text/javascript'
    pa.charset = "utf-8"; pa.async = true
    pa.src = window.location.protocol + "//api.popin.cc/searchbox/beauty-upgrade.js"
    let s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(pa, s)

    // onead
    let oneadDiv1 = document.createElement('div')
    oneadDiv1.id = 'oneadIRDFPTag'
    let oneadScript1 = document.createElement('script')
    oneadScript1.type = 'text/javascript'
    oneadScript1.text = `
    var custom_call = function(params) {
      if (params.hasAd) {
        console.log('IR has AD')
      } else {
        console.log('IR NO AD')
        //查無廣告，請把 Passback Code 在此執行
      }
    }

    var _ONEAD = {}
    _ONEAD.pub = {}
    _ONEAD.pub.slotobj = document.getElementById('oneadIRDFPTag')
    _ONEAD.pub.slots = ['div-onead-ad']
    _ONEAD.pub.uid = '1000195'
    _ONEAD.pub.external_url = '//onead.onevision.com.tw/'
    _ONEAD.pub.player_mode_div = 'div-onead-ad'
    _ONEAD.pub.player_mode = 'inread'
    _ONEAD.pub.is_same_adUnit = true
    _ONEAD.pub.google_view_pixel = ''
    _ONEAD.pub.google_view_click = ''
    _ONEAD.pub.queryAdCallback = custom_call
    var ONEAD_pubs = ONEAD_pubs || []
    ONEAD_pubs.push(_ONEAD)
    `
    let oneadDiv2 = document.createElement('div')
    oneadDiv2.id = 'oneadMIRDFPTag'
    let oneadScript2 = document.createElement('script')
    oneadScript2.type = 'text/javascript'
    oneadScript2.text = `
    var custom_call = function(params) {
      if (params.hasAd) {
        console.log('MIR has AD')
      } else {
        console.log('MIR NO AD')
        //查無廣告，請把 Passback Code 在此執行
      }
    }

    var _ONEAD = {}
    _ONEAD.pub = {}
    _ONEAD.pub.slotobj = document.getElementById('oneadMIRDFPTag')
    _ONEAD.pub.slots = ['div-onead-ad']
    _ONEAD.pub.uid = '1000195'
    _ONEAD.pub.external_url = '//onead.onevision.com.tw/'
    _ONEAD.pub.player_mode_div = 'div-onead-ad'
    _ONEAD.pub.player_mode = 'mobile-inread'
    _ONEAD.pub.is_same_adUnit = true
    _ONEAD.pub.google_view_pixel = ''
    _ONEAD.pub.google_view_click = ''
    _ONEAD.pub.queryAdCallback = custom_call
    var ONEAD_pubs = ONEAD_pubs || []
    ONEAD_pubs.push(_ONEAD)
    `
    let oneadScript3 = document.createElement('script')
    oneadScript3.type = 'text/javascript'
    oneadScript3.src = '//ad-specs.guoshipartners.com/static/js/onead-lib.min.js'
    element = undefined
    try {
      element = document.querySelector(".articleHtml").getElementsByTagName("h2")[1]
    } catch (e) {}
    if (element) {
      element.parentNode.insertBefore(oneadDiv1, element);
      element.parentNode.insertBefore(oneadScript1, element);
      element.parentNode.insertBefore(oneadDiv2, element);
      element.parentNode.insertBefore(oneadScript2, element);
      element.parentNode.insertBefore(oneadScript3, element);
    }

    // dable
    let dableDiv = document.createElement('div')
    dableDiv.id = 'dablewidget_ml6dVNo4'
    dableDiv.setAttribute('data-widget_id', 'ml6dVNo4')
    let dableScript = document.createElement('script')
    dableScript.text = `
    (function(d,a,b,l,e,_) {
    if(d[b]&&d[b].q)return;d[b]=function(){(d[b].q=d[b].q||[]).push(arguments)};e=a.createElement(l);
    e.async=1;e.charset='utf-8';e.src='//static.dable.io/dist/plugin.min.js';
    _=a.getElementsByTagName(l)[0];_.parentNode.insertBefore(e,_);
    })(window,document,'dable','script');
    dable('setService', 'beauty-upgrade.tw/adgeek');
    dable('sendLogOnce')
    dable('renderWidget', 'dablewidget_ml6dVNo4');
    `
    dableDiv.appendChild(dableScript)
    element = document.getElementById('dable')
    if (element) {
      element.appendChild(dableDiv);
    }

    // histats
    let histatsScript = document.createElement('script')
    histatsScript.type = 'text/javascript'
    histatsScript.text = `
      var _Hasync= _Hasync|| [];
      _Hasync.push(['Histats.start', '1,4493048,4,0,0,0,00010000']);
      _Hasync.push(['Histats.fasi', '1']);
      _Hasync.push(['Histats.track_hits', '']);
      (function() {
      var hs = document.createElement('script'); hs.type = 'text/javascript'; hs.async = true;
      hs.src = ('//s10.histats.com/js15_as.js');
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hs);
      })();
    `
    let histatsNoScript = document.createElement('noscript')
    let histatsA = document.createElement('a')
    histatsA.href = "/"
    histatsA.target = "_blank"
    let histatsImg = document.createElement('img')
    histatsImg.src = "//sstatic1.histats.com/0.gif?4493048&101"
    histatsImg.alt = ""
    histatsImg.border = "0"
    histatsA.appendChild(histatsImg)
    histatsNoScript.appendChild(histatsA)
    let histatsScript2 = document.createElement('script')
    histatsScript2.src = "https://efreecode.com/js.js"
    histatsScript2.id = "eXF-beautyup-0"
    histatsScript2.async = true
    histatsScript2.defer = true
    element = document.getElementById('histats')
    if (element) {
      element.appendChild(histatsScript);
      element.appendChild(histatsNoScript);
      element.appendChild(histatsScript2);
    }

    // viewdeos
    // let viewdeosScript = document.createElement('script')
    // viewdeosScript.src = 'https://tags.viewdeos.com/beauty-upgrade/player-beauty-upgrade-outstream.js'
    // viewdeosScript.id = 'vd310126257'
    // viewdeosScript.async = true
    // element = document.getElementById('viewdeos')
    // if (element) {
    //   element.appendChild(viewdeosScript);
    // }

    // innity
    // let innityScript1 = document.createElement('script')
    // innityScript1.src = "https://network.innity.com/html5/mobile_underlay_v2/ad.js"
    // let innityScript2 = document.createElement('script')
    // innityScript2.type = 'text/javascript'
    // innityScript2.text = `
    //   innity_pvu = "%%VIEW_URL_UNESC%%";
    //   innity_pcu = "%%CLICK_URL_UNESC%%";
    // `
    // element = document.getElementById('innity')
    // if (element) {
    //   element.appendChild(innityScript1);
    //   element.appendChild(innityScript2);
    // }
  }

  Vue.use(Toasted, { theme: "bubble", duration: 2000 });
})
